import { Link } from 'gatsby'
import React from 'react'
import styles from './navigation.module.css'

export default ({ exhibitions }) => {
  return (
    <nav className={styles.navigationWrapper} role="navigation">
      <ul className={styles.navigation}>
        <li className={styles.navigationItem}>
          <Link
            to="/"
            className={styles.navigationItemLink}
            activeClassName={styles.navigationItemActive}
          >
            Home
          </Link>
        </li>
        {(exhibitions || []).map(({ node }) => (
          <li className={styles.navigationItem}>
            <Link
              to={`/exhibition/${node.slug}/`}
              className={styles.navigationItemLink}
              activeClassName={styles.navigationItemActive}
            >
              {node.title}
            </Link>
          </li>
        ))}
        <li className={styles.navigationItem}>
          <Link
            to="/contact"
            className={styles.navigationItemLink}
            activeClassName={styles.navigationItemActive}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  )
}
