import { Link } from 'gatsby'
import React from 'react'
import styles from './button.module.css'

export default ({ children, to, type }) =>
  to ? (
    <Link to={to} className={styles.button}>
      {children}
    </Link>
  ) : (
    <button type={type} className={styles.button}>
      {children}
    </button>
  )
