import { Link } from 'gatsby'
import React from 'react'
import styles from './full-page-navigation.module.css'

export default ({ exhibitions, onItemClick }) => {
  return (
    <nav className={styles.fullPageNavContainer} role="navigation">
      <button
        onClick={onItemClick}
        className={styles.closeMenu}
      />
      <ul className={styles.fullPageNav}>
        <li className={styles.fullPageNavItem}>
          <Link
            onClick={onItemClick}
            to="/"
            className={styles.fullPageNavItemLink}
            activeClassName={styles.fullPageNavItemActive}
          >
            Home
          </Link>
        </li>
        {(exhibitions || []).map(({ node }) => (
          <li className={styles.fullPageNavItem}>
            <Link
              onClick={onItemClick}
              to={`/exhibition/${node.slug}/`}
              className={styles.fullPageNavItemLink}
              activeClassName={styles.fullPageNavItemActive}
            >
              {node.title}
            </Link>
          </li>
        ))}
        <li className={styles.fullPageNavItem}>
          <Link
            onClick={onItemClick}
            to="/reviews"
            className={styles.fullPageNavItemLink}
            activeClassName={styles.fullPageNavItemActive}
          >
            Reviews
          </Link>
        </li>
        <li className={styles.fullPageNavItem}>
          <Link
            onClick={onItemClick}
            to="/contact"
            className={styles.fullPageNavItemLink}
            activeClassName={styles.fullPageNavItemActive}
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  )
}
