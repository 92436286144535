import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import Container from './container'
import FullPageNavigation from './full-page-navigation'
import { Link } from 'gatsby'
import Navigation from './navigation'
import React from 'react'
import base from './base.css'
import styles from './layout.module.css'

class Template extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isMenuShowing: false,
    }
  }

  render() {
    const { location, children, exhibitions } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Container>
        <div className={styles.topbar}>
          <span className={styles.topbarTitle}>Tommy Clancy Photography</span>
          <div className={styles.topbarSecondaryActions}>
            <Link className={styles.reviewLink} to="/reviews">
              Reviews
            </Link>
            <a
              className={styles.commercialLink}
              target="_blank"
              href="http://commercial.tommyclancyphotography.com/"
            >
              Commercial ↗
            </a>
          </div>
          <button onClick={() => {
            this.setState({
              isMenuShowing: true,
            })
          }} className={styles.burgerMenu} />
        </div>
        {this.state.isMenuShowing && (
          <FullPageNavigation
            onItemClick={() => {
              this.setState({
                isMenuShowing: false,
              })
            }}
            exhibitions={exhibitions}
          />
        )}
        <Navigation exhibitions={exhibitions} />
        <div className={styles.mainContent}>{children}</div>
        <footer className="footer">
          &#169; 2020 Tommy Clancy Photography; All rights reserved
        </footer>
      </Container>
    )
  }
}

export default Template
